import * as React from "react";
import styled from "styled-components";
import H1 from "./H1";
import H5 from "./H5";

interface HeaderProps {
  title?: string;
  subtitle?: string;
  description?: string;
  image?: string;
}

const Header: React.FC<HeaderProps> = ({
  title = "",
  subtitle = "",
  description = "",
  image = "",
}) => {
  return (
    <HeaderWrapper>
      <HeaderColumn>
        {!!subtitle && <H5>{subtitle}</H5>}
        {!!title && <H1>{title}</H1>}
        {!!description && <p>{description}</p>}
      </HeaderColumn>
      <HeaderColumn>
        <img
          alt="Demo"
          src="https://teampay.se/wp-content/uploads/2020/04/demo-text-571x1024.png"
          style={{ maxWidth: "50%" }}
        />
      </HeaderColumn>
    </HeaderWrapper>
  );
};

export default Header;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HeaderColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  & > img {
    justify-self: center;
    margin: auto;
  }
`;

import React from "react";
import styled from "styled-components";

const H5: React.FC = ({ children }) => {
  return <StyledH5>{children}</StyledH5>;
};

export default H5;

const StyledH5 = styled.h5``;

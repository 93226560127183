import styled from "styled-components";

// const FormRow = () => {
//   return (
//     <div>

//     </div>
//   )
// }

const FormRow = styled.div`
  clear: both;
  display: flex;
`;
export default FormRow;

import React from "react";
import styled from "styled-components";

const H1: React.FC = ({ children }) => {
  return <StyledH1>{children}</StyledH1>;
};

export default H1;

const StyledH1 = styled.h1``;

import React from "react";
import styled from "styled-components";

const ShadowBox: React.FC = ({ children }) => {
  return <ShadowBoxWrapper>{children}</ShadowBoxWrapper>;
};

export default ShadowBox;

const ShadowBoxWrapper = styled.div`
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.229);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 30px 30px 30px 30px;
  padding: 60px 60px 60px 60px;
`;

import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ExistsScreen from "./screens/ExistsScreen";
import StartScreen from "./screens/StartScreen";

const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={StartScreen} />
        <Route path="/exists" exact component={ExistsScreen} />
      </Switch>
    </Router>
  );
};

export default AppRouter;

import React from "react";
import styled from "styled-components";

const H3: React.FC = ({ children }) => {
  return <StyledH3>{children}</StyledH3>;
};

export default H3;

const StyledH3 = styled.h3``;

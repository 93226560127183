import QRCode from "qrcode.react";
import React from "react";
import H1 from "src/components/H1";
import H3 from "src/components/H3";
import Header from "src/components/Header";
import ShadowBox from "src/components/ShadowBox";
import { DefaultScreenProps } from "src/lib/types";

const ExistsScreen: React.FC<DefaultScreenProps> = ({ history }) => {
  return (
    <>
      <Header
        subtitle="Hoppsan"
        title="Ni har ju redan TeamPay!"
        description={
          "Prata med någon chefig som kan hjälpa dig att komma igång!"
        }
      />
      <ShadowBox>
        <H1>Vill du bara prova demot?</H1>
        <H3>Scanna den här QR-koden med din mobil...</H3>
        <QRCode value="https://app2.teampay.se" size={256} />
        <H3>Eller klicka här om du redan är på mobilen...</H3>
        <button
          onClick={() => (window.location.href = "https://app2.teampay.se")}
        >
          Till appen!
        </button>
      </ShadowBox>
    </>
  );
};

export default ExistsScreen;

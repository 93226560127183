import * as React from "react";
import * as ReactDOM from "react-dom";
import styled from "styled-components";
import "./index.css";
import AppRouter from "./Router";
import * as serviceWorker from "./serviceWorker";

const ContentWrapper = styled.div`
  max-width: 1120px;
  margin: auto;
`;

ReactDOM.render(
  <React.StrictMode>
    <ContentWrapper>
      <AppRouter />
    </ContentWrapper>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
